import React from 'react';
import Logo from './Logo';

const FormLayout = ({ children }) => {
  return (
    <div className="bg-white min-h-screen flex flex-col items-center pt-7 pb-20">
      <div className="w-full max-w-sm flex flex-col items-center px-4">
        <Logo />
        {children}
      </div>
    </div>
  );
};

export default FormLayout;
