import React from "react";
import FormLayout from "../components/FormLayout";

const ThankYouMessage = () => {
  return (
    <FormLayout>
      <div className="text-center my-20">
        <h2 className="text-left text-2xl font-bold mb-4">
          解答ありがとうございました。
        </h2>
        <p className="mb-6 font-bold">メールにて採点結果をお知らせします。</p>
      </div>
    </FormLayout>
  );
};

export default ThankYouMessage;
