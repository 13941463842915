// src/atoms/answerAtom.js
import { atom } from "jotai";

export const postDataAtom = atom({
  index: 0,
  userAnswer: {
    reason: "",
    result: "",
  },
  exampleAnswer: {
    reason: "",
    result: "",
  },
  question: "",
  estimationType: null,
  user: {
    name: "",
    companyName: "",
    email: "",
    phone: "",
    birth: "",
    disiredCompanies: [],
  },
});

export const fermiInquiryFormAtom = atom({
  user: {
    name: "",
    companyName: "",
    email: "",
    phone: "",
    birth: "",
  },
});

export const responseTimeAtom = atom(0);
