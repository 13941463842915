import React, { useState, useEffect } from "react";
import { useAtom } from "jotai";
import axios from "axios";
import { postDataAtom, responseTimeAtom } from "../store/atoms";
import { useNavigate } from "react-router-dom";
import FormLayout from "../components/FormLayout";
import SubmitButton from "../components/SubmitButton";

const AnswerForm = () => {
  const [postData, setPostData] = useAtom(postDataAtom);
  const [, setResponseTime] = useAtom(responseTimeAtom);
  const [answerError, setAnswerError] = useState("");
  const [rationaleError, setRationaleError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    const fetchQuestion = async () => {
      try {
        const response = await axios.get("/api/service1");
        // console.log(response.data)
        setPostData({
          ...postData,
          index: response.data.indexNum,
          question: response.data.question,
          estimationType: response.data.estimationType,
          exampleAnswer: {
            reason: response.data.answer.reason,
            result: response.data.answer.result,
          },
        });
        setIsLoading(false);
        setStartTime(new Date()); // 問題のロードが完了した時刻を記録
      } catch (error) {
        console.error("問題文の取得に失敗しました。", error);
      }
    };

    fetchQuestion();
  }, []);

  const validateForm = (e) => {
    e.preventDefault();
    const endTime = new Date(); // フォーム送信時の時刻を記録
    let isError = false;

    if (!postData.userAnswer.reason.trim()) {
      setAnswerError("解答を入力してください。");
      isError = true;
    } else {
      setAnswerError("");
    }

    if (!postData.userAnswer.result.trim()) {
      setRationaleError("根拠を入力してください。");
      isError = true;
    } else {
      setRationaleError("");
    }

    if (!isError) {
      const timeTaken = (endTime - startTime) / 1000; // 秒単位で計算
      // console.log(`回答にかかった時間: ${timeTaken}秒`);
      setResponseTime(timeTaken);

      localStorage.setItem("postData", JSON.stringify(postData));
      localStorage.setItem("responseTime", timeTaken);

      navigate("/inquiry");
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin h-10 w-10 border-4 border-gray-500 rounded-full border-t-transparent"></div>
      </div>
    );
  }

  return (
    <FormLayout>
      <h2 className="text-left text-4xl font-bold mb-4">
        Q. {postData.question}
      </h2>
      <p className="text-left text-gray-700 text-base mb-6">
        ※推定の根拠を採点します。なるべく丁寧に記載してください。
      </p>
      <form onSubmit={validateForm} className="w-full">
        <div className="mb-4">
          <label
            htmlFor="answer"
            className="block text-gray-700 font-bold mb-2"
          >
            解答
          </label>
          <input
            value={postData.userAnswer.result}
            onChange={(e) =>
              setPostData({
                ...postData,
                userAnswer: { ...postData.userAnswer, result: e.target.value },
              })
            }
            type="text"
            id="answer"
            name="answer"
            placeholder="〜円"
            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-gray-500"
          />
          {answerError && (
            <p className="text-gray-500 text-sm mt-1">{answerError}</p>
          )}
        </div>
        <div className="mb-6">
          <label
            htmlFor="rationale"
            className="block text-gray-700 font-bold mb-2"
          >
            根拠
          </label>
          <textarea
            value={postData.userAnswer.reason}
            onChange={(e) =>
              setPostData({
                ...postData,
                userAnswer: { ...postData.userAnswer, reason: e.target.value },
              })
            }
            id="rationale"
            name="rationale"
            className="appearance-none border rounded w-full h-80 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-gray-500"
          ></textarea>
          {rationaleError && (
            <p className="text-gray-500 text-sm">{rationaleError}</p>
          )}
        </div>
        <div className="mb-6">
          <SubmitButton text="回答する" />
        </div>
      </form>
    </FormLayout>
  );
};

export default AnswerForm;
