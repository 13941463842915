import React from "react";
import Logo from "./../Logo";

const FermiExample = () => {
  return (
    <>
      <div className="border-solid border-2 border-gray-900 mx-1 px-2 rounded-md pb-8">
        <div className="">
          <div className="flex justify-center mt-6">
            <Logo />
          </div>

          <h2 className="text-3xl font-bold mb-2">
            Q. 表参道にある美容室の一日の平均売上を推定せよ。
            <br />
            （回答時間：5分）
          </h2>
          <p className="mb-3">
            ※推定の根拠を採点します。なるべく丁寧に記載してください。
          </p>

          <div className="mb-3">
            <div className="text-lg font-semibold">解答</div>
            <p className="border-solid border border-gray-900 p-2 rounded-md">
              約25万円
            </p>
          </div>

          <div className="text-lg font-semibold">根拠</div>

          <div className="border-solid border border-gray-900 px-2 rounded-md py-1">
            <p>前提として、以下のとおりの設定を置きました。</p>

            <ul className="list-disc px-4 mb-2">
              <li>表参道という好立地で、予約が常に満員の人気店</li>
              <li>席数5席の規模の店</li>
            </ul>

            <p className="mb-2">
              美容室の一日の平均売上は、「来客数×顧客単価」で算出いたしました。
              <br />
              来客数については精度を上げあるため、もう少し細かく分解して考えており、「席数×稼働率×回転率×営業時間」と分解できます。
              <br />
              つまり、美容室の一日の平均売上は「席数×稼働率×回転率×営業時間×顧客単価」で計算できることになります。
              <br />
              ここで、来客数にバラつきのあるセグメントに分けて考えることを検討します。通常、時間帯や平日と土日・祝日とで分けることが考えられますが、今回は予約満員の人気店であることから各因数に差分は生じにくく、セグメントを分けずに計算いたしました。
              <br />
              各因数については、以下のように考えております。
            </p>

            <p className="mb-2">
              【席数】
              <br />
              前提で設定したとおり、5席
            </p>
            <p className="mb-2">
              【稼働率】
              <br />
              常に予約満員の人気店のため、100%
            </p>
            <p className="mb-2">
              【回転率】
              <br />
              施術メニューごとに異なることが想定されるため、①フルコース、②カット・シャンプー、③カラー・トリートメントなどのサブメニューのみ、の場合を考えます。②にかかる時間を1時間、③にかかる時間を0.5時間とし、①のフルコースは1.5時間とします。通常の美容室であれば②がメインとなりますが、表参道の人気店であれば①③の需要も高いと考えられるため、それぞれの顧客比率を①：②：③＝１：２：１と仮定。加重平均をとると、1.5時間×1/4+1時間×1/2+0.5時間×1/4となり、全メニューの平均施術時間は1時間。よって、1時間あたりの回転数である回転率は1
            </p>
            <p className="mb-2">
              【営業時間】
              <br />
              10:00から20:00まで営業しているとして、10時間
            </p>
            <p className="mb-2">
              【顧客単価】
              <br />
              施術メニューごとに異なることが想定されるため、①フルコース、②カット・シャンプー、③カラー・トリートメントなどのサブメニューのみ、の場合に分けて考える。それぞれの単価を①7,000円、②5,000円、③3,000円と仮定し、顧客比率を加重平均すると、7,000円×1/4+5,000円×1/2+3,000円×1/4となり、平均顧客単価は5,000円
              <br />
              これらを掛け合わせると、5席×100%×1回転×10時間×5,000円となり、一日の平均売上は約25万円。
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default FermiExample;
