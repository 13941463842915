import React, { useState } from "react";
import { useAtom } from "jotai";
import axios from "axios";
import FormLayout from "../components/FormLayout";
import SubmitButton from "../components/SubmitButton";
import { useNavigate } from "react-router-dom";
import { fermiInquiryFormAtom } from "../store/atoms";
import DatePicker from "../components/organisms/DatePicker";

const FermiInquiryForm = () => {
  const [fermiInquiryFormData, setFermiInquiryFormData] =
    useAtom(fermiInquiryFormAtom);
  const navigate = useNavigate();
  const [nameError, setNameError] = useState("");
  const [companyNameError, setcompanyNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [birthError, setBirthError] = useState("");

  const validateForm = async (e) => {
    e.preventDefault();
    let isError = false;

    if (!fermiInquiryFormData.user.name.trim()) {
      setNameError("名前を入力してください。");
      isError = true;
    } else {
      setNameError("");
    }

    if (!fermiInquiryFormData.user.companyName.trim()) {
      setcompanyNameError("会社名を入力してください。");
      isError = true;
    } else {
      setcompanyNameError("");
    }

    if (!fermiInquiryFormData.user.email.trim()) {
      setEmailError("メールアドレスを入力してください。");
      isError = true;
    } else {
      setEmailError("");
    }
    if (!fermiInquiryFormData.user.phone.trim()) {
      setPhoneError("電話番号を入力してください。");
      isError = true;
    } else {
      setPhoneError("");
    }

    if (!fermiInquiryFormData.user.birth.trim()) {
      setBirthError("生年月日を入力してください。");
      isError = true;
    } else {
      setBirthError("");
    }

    if (!isError) {
      const data = {
        user_name: fermiInquiryFormData.user.name,
        company_name: fermiInquiryFormData.user.companyName,
        mail_address: fermiInquiryFormData.user.email,
        telephone_number: fermiInquiryFormData.user.phone,
        birth: fermiInquiryFormData.user.birth,
      };

      try {
        await axios.post("/api/service4", data).then((res) => {
          console.log(res.data);
        });

        navigate("/thank-your-inquiry");
      } catch (error) {
        console.error("送信エラー:", error);
      }
    }
  };

  const handleDateChange = (date) => {
    if (fermiInquiryFormData.user.birth !== date) {
      setFermiInquiryFormData({
        ...fermiInquiryFormData,
        user: { ...fermiInquiryFormData.user, birth: date },
      });
    }
  };

  return (
    <FormLayout>
      <h2 className="text-left text-4xl font-bold mb-6">お問い合わせ</h2>
      <form onSubmit={validateForm} className="w-full">
        {/* 名前の入力フィールド */}
        <div className="mb-4">
          <label htmlFor="name" className="block text-gray-700 mb-2">
            <span className="bg-neutral-700 text-white rounded-md px-2 py-1 mr-2">
              必須
            </span>
            <span className="font-bold">名前</span>
          </label>
          <input
            value={fermiInquiryFormData.user.name}
            onChange={(e) =>
              setFermiInquiryFormData({
                ...fermiInquiryFormData,
                user: { ...fermiInquiryFormData.user, name: e.target.value },
              })
            }
            type="text"
            id="name"
            name="name"
            className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-gray-500"
          />
          {nameError && <p className="text-gray-500 text-sm">{nameError}</p>}
        </div>

        {/* 会社名の入力フィールド */}
        <div className="mb-4">
          <label htmlFor="companyName" className="block text-gray-700 mb-2">
            <span className="bg-neutral-700 text-white rounded-md px-2 py-1 mr-2">
              必須
            </span>
            <span className="font-bold">会社名</span>
          </label>
          <input
            value={fermiInquiryFormData.user.companyName}
            onChange={(e) =>
              setFermiInquiryFormData({
                ...fermiInquiryFormData,
                user: {
                  ...fermiInquiryFormData.user,
                  companyName: e.target.value,
                },
              })
            }
            type="text"
            id="companyName"
            name="companyName"
            className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-gray-500"
          />
          {companyNameError && (
            <p className="text-gray-500 text-sm">{companyNameError}</p>
          )}
        </div>

        {/* メールアドレスの入力フィールド */}
        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-700 mb-2">
            <span className="bg-neutral-700 text-white rounded-md px-2 py-1 mr-2">
              必須
            </span>
            <span className="font-bold">メールアドレス</span>
          </label>
          <input
            value={fermiInquiryFormData.user.email}
            onChange={(e) =>
              setFermiInquiryFormData({
                ...fermiInquiryFormData,
                user: { ...fermiInquiryFormData.user, email: e.target.value },
              })
            }
            type="email"
            id="email"
            name="email"
            className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-gray-500"
          />
          {emailError && <p className="text-gray-500 text-sm">{emailError}</p>}
        </div>

        {/* 電話番号の入力フィールド */}
        <div className="mb-4">
          <label htmlFor="phone" className="block text-gray-700 mb-2">
            <span className="bg-neutral-700 text-white rounded-md px-2 py-1 mr-2">
              必須
            </span>
            <span className="font-bold">電話番号</span>
          </label>
          <input
            value={fermiInquiryFormData.user.phone}
            onChange={(e) =>
              setFermiInquiryFormData({
                ...fermiInquiryFormData,
                user: { ...fermiInquiryFormData.user, phone: e.target.value },
              })
            }
            type="tel"
            id="phone"
            name="phone"
            className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-gray-500"
          />
          {phoneError && <p className="text-gray-500 text-sm">{phoneError}</p>}
        </div>

        <div className="text-left text-gray-600 text-sm mb-4">
          メールにて採点結果や改善のアドバイスをご連絡させていただきます。
        </div>

        {/* 生年月日の入力フィールド */}
        <div className="mb-4">
          <label htmlFor="birth" className="block text-gray-700 mb-2">
            <div className="mb-2">
              <span className="bg-neutral-700 text-white rounded-md px-2 py-1 mr-2">
                必須
              </span>
              <span className="font-bold">生年月日</span>
            </div>

            <div className="w-full">
              <DatePicker onDateChange={handleDateChange} />
            </div>

            {birthError && (
              <p className="text-gray-500 text-sm">{birthError}</p>
            )}
          </label>
        </div>

        <SubmitButton text="解答を送信する" />

        <div className="text-center mt-4 text-gray-700">
          <a
            href="https://remedy-tokyo.co.jp/privacy-protection"
            target="_blank"
            rel="noopener noreferrer"
            className="underline"
          >
            プライバシーポリシー
          </a>
          に同意する
        </div>
      </form>
    </FormLayout>
  );
};

export default FermiInquiryForm;
