export const desiredCompanies = [
  "McKinsey & Company",
  "Boston Consulting Group",
  "Bain & Company",
  "A.T. Kearney, Inc.",
  "Arthur D. Little",
  "Roland Berger",
  "Strategy&",
  "Corporate Directions, Inc.",
  "P&E Directions, Inc.",
  "PricewaterhouseCoopers",
  "Ernst & Young Global Limited",
  "KPMG International Cooperative",
  "Monitor Deloitte",
  "Nomura Research Institute, Ltd.",
  "Field Management Consulting",
  "Industrial Growth Platform Inc.",
  "Revamp Corporation",
  "Dentsu Technology and Consulting",
  "Accenture plc",
  "ABeam Consulting Ltd.",
  "POINT - Ignition Point Inc.",
  "SIGMAXYZ Inc.",
  "Rise Corporation",
];

// export const desiredCompanies = [
//   { id: 1, name: "McKinsey & Company" },
//   { id: 2, name: "Boston Consulting Group" },
//   { id: 3, name: "Bain & Company" },
//   { id: 4, name: "A.T. Kearney, Inc." },
//   { id: 5, name: "Arthur D. Little" },
//   { id: 6, name: "Roland Berger" },
//   { id: 7, name: "Strategy&" },
//   { id: 8, name: "Corporate Directions, Inc." },
//   { id: 9, name: "P&E Directions, Inc." },
//   { id: 10, name: "Field Management Consulting" },
//   { id: 11, name: "Industrial Growth Platform Inc." },
//   { id: 12, name: "Revamp Corporation" },
//   { id: 13, name: "Dentsu Technology and Consulting" },
//   { id: 14, name: "PricewaterhouseCoopers" },
//   { id: 15, name: "Ernst & Young Global Limited" },
//   { id: 16, name: "KPMG International Cooperative" },
//   { id: 17, name: "Accenture plc" },
//   { id: 18, name: "Nomura Research Institute, Ltd." },
//   { id: 19, name: "Bain & Company Japan, Inc." },
//   { id: 20, name: "ABeam Consulting Ltd." },
//   { id: 21, name: "POINT - Ignition Point Inc." },
//   { id: 22, name: "SIGMAXYZ Inc." },
//   { id: 23, name: "Rise Corporation" },
//   { id: 24, name: "Revamp Corporation" },
// ];
