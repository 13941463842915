import React, { useState, useEffect } from "react";

const DatePicker = ({ onDateChange }) => {
  const [years, setYears] = useState([]);
  const [months] = useState([...Array(12).keys()].map((i) => i + 1));
  const [days, setDays] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedDay, setSelectedDay] = useState("");

  // 初回レンダリング時に年リストを設定
  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const yearsArray = [];
    for (let i = currentYear; i >= 1950; i--) {
      yearsArray.push(i);
    }
    setYears(yearsArray);
  }, []);

  // 年または月が変更されたときに日リストを更新
  useEffect(() => {
    if (selectedYear && selectedMonth) {
      const daysInMonth = new Date(selectedYear, selectedMonth, 0).getDate();
      const daysArray = [];
      for (let i = 1; i <= daysInMonth; i++) {
        daysArray.push(i);
      }
      setDays(daysArray);
      setSelectedDay((prevDay) => (prevDay > daysInMonth ? "" : prevDay));
    } else {
      setDays([]);
    }
  }, [selectedYear, selectedMonth]);

  useEffect(() => {
    if (selectedYear && selectedMonth && selectedDay) {
      onDateChange(`${selectedYear}-${selectedMonth}-${selectedDay}`);
    }
  }, [selectedYear, selectedMonth, selectedDay, onDateChange]);

  return (
    <div className="flex space-x-3">
      <div className="flex-auto">
        <select
          id="year"
          value={selectedYear}
          onChange={(e) => setSelectedYear(Number(e.target.value))}
          className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-gray-500"
        >
          <option value=""></option>
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
      <div className="flex-auto">
        <select
          id="month"
          value={selectedMonth}
          onChange={(e) => setSelectedMonth(Number(e.target.value))}
          className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-gray-500"
        >
          <option value=""></option>
          {months.map((month) => (
            <option key={month} value={month}>
              {month}
            </option>
          ))}
        </select>
      </div>
      <div className="flex-auto">
        <select
          id="day"
          value={selectedDay}
          onChange={(e) => setSelectedDay(Number(e.target.value))}
          className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-gray-500"
        >
          <option value=""></option>
          {days.map((day) => (
            <option key={day} value={day}>
              {day}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default DatePicker;
