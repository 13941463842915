import React from "react";
import { Link } from "react-router-dom";

const LinkButton = ({ to, text }) => {
  return (
    <Link
      to={to}
      className="bg-neutral-700 text-white py-4 px-4 rounded w-full block text-center mt-4 hover:bg-neutral-800 active:bg-neutral-900 focus:outline-none focus:shadow-outline"
    >
      {text}
    </Link>
  );
};

export default LinkButton;
