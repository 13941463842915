import React from "react";
import FormLayout from "../components/FormLayout";

const ThankYourInquiry = () => {
  return (
    <FormLayout>
      <div className="text-center my-20">
        <h2 className="text-left text-2xl font-bold mb-4">
          解答いただきありがとうございました。
        </h2>
      </div>
    </FormLayout>
  );
};

export default ThankYourInquiry;
