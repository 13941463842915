import React from 'react';

const Logo = () => {
  return (
    <div className="flex justify-center items-center w-14 h-14 rounded-full mb-6">
      <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="logo" className="w-full h-full" style={{ objectFit: 'cover' }} />
    </div>
  );
};

export default Logo;
