import React from "react";
import FormLayout from "../components/FormLayout";
import LinkButton from "../components/LinkButton";
import FermiExample from "../components/organisms/FermiExample";

const Start = () => {
  return (
    <FormLayout>
      <div>
        <h2 className="text-center text-4xl font-bold mb-8">AIケース面接</h2>

        <section>
          <div className="text-2xl font-bold">概要</div>
          <p className="mb-3">
          このアプリは、Bain & Companyで勤務していた戦略コンサルタントが監修したケース面接の対策ツールです。 <br />
          実際のケース面接を想定した問題が出題され、ご回答に対してAIが自動で評価・フィードバックしてくれるサービスです。 <br />
          <br />
          *本サービスの出力は自動生成されており、エラーを含む可能性があります。実際にファームへの応募をする際には、弊社の専門エージェントの意見を参考にするよう、お願い致します。
          </p>
        </section>

        <div className="my-4">
          <LinkButton to={"answer"} text="実際の問題を解いてみる" />
        </div>

        <p className="text-2xl mb-6 font-bold">例</p>
        <FermiExample />

        <LinkButton to={"answer"} text="実際の問題を解いてみる" />
        <LinkButton to={"fermi-inquire"} text="解き方を聞く" />
      </div>
    </FormLayout>
  );
};

export default Start;
